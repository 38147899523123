import {
  GroupTypeEnum,
  ModulePathsEnum,
  ResultToggleMoreLength,
  RiskModeEnum,
  RoleTypeEnum,
} from './global-search.enum'
import { configurationAPI } from '@/shared/api/index'
import { APIStageEnum } from '@/shared/utils'

const lineListPath = '/service/line-list'
const collateralPath = '/service/aperture/collateral'

export default {
  data() {
    return {
      isCallMode: true,
    }
  },
  computed: {
    roleMenuList() {
      return this.$store.state?.userInfo?.roleConfiguration?.menuList ?? []
    },
    isInvestment() {
      const roleType = this.$store.state.userInfo?.profile?.roleType
      return this.$utils.isEqualStringIgnoreCase(
        RoleTypeEnum.investment,
        roleType
      )
    },
    cmSearchGroups() {
      return [
        {
          groupName: this.$t('collateral'),
          typeCode: GroupTypeEnum.collateral,
          requiredMenu: collateralPath,
          normalizeResult: this.cmNormalizeResult,
          getResultContents: this.getCollateralResultContents,
          modulePaths: ModulePathsEnum.cm,
        },
        {
          groupName: this.$t('asset'),
          typeCode: GroupTypeEnum.asset,
          requiredMenu: collateralPath,
          normalizeResult: this.cmNormalizeResult,
          getResultContents: this.getAssetResultContents,
          modulePaths: ModulePathsEnum.cm,
        },
        {
          groupName: this.$t('search_result_group_line'),
          typeCode: GroupTypeEnum.line,
          requiredMenu: lineListPath,
          normalizeResult: this.cmNormalizeResult,
          getResultContents: this.getLineResultContents,
          modulePaths: ModulePathsEnum.cm,
        },
        {
          groupName: this.$t('loan'),
          typeCode: GroupTypeEnum.loan,
          requiredMenu: lineListPath,
          normalizeResult: this.cmNormalizeResult,
          getResultContents: this.getLoanResultContents,
          modulePaths: ModulePathsEnum.cm,
        },
      ].filter((searchType) => {
        return this.roleMenuList.some(
          (menu) => menu.path === searchType.requiredMenu
        )
      })
    },
    needSearchRiskEvent() {
      return this.searchTypes.includes(GroupTypeEnum.collateralCall)
    },
  },
  created() {
    if (this.needSearchRiskEvent) {
      this.getCMConfig()
    }
  },
  methods: {
    getCMConfig() {
      configurationAPI
        .listInstitutionLevelConfigs(APIStageEnum.service, {
          categoryCode: 'collateralDataHub',
        })
        .then((cmInstitutionConfig) => {
          const riskEventMode =
            cmInstitutionConfig.lenderInstitutionLevelConfigs.riskEventMode
          this.isCallMode = this.$utils.isEqualStringIgnoreCase(
            riskEventMode,
            RiskModeEnum.call
          )
        })
    },
    getLineContentPath(locId) {
      const peopleEntitiesPath = '/service/line/people-and-entities'
      const contentNeedAuth = this.roleMenuList.some(
        (menu) => menu.path === peopleEntitiesPath
      )
      return contentNeedAuth ? `${peopleEntitiesPath}?locId=${locId}` : null
    },
    normalizeLineParties(result, contentRouter) {
      const parties = result?.parties ?? []
      return parties.map((item) => {
        let roles = item?.roles ?? []
        roles = this.$utils.format.text(roles.join(', '))
        const partyName = this.$utils.format.text(item?.partyName)
        return {
          contentText: `${partyName} (${roles})`,
          contentRouter,
        }
      })
    },
    normalizeLineAdvisors(result, contentRouter) {
      const advisors = result?.advisors ?? []
      return advisors.map((item) => {
        const advisorName = this.$utils.format.text(item?.advisorName)
        const advisorType = this.$utils.format.text(
          item?.advisorType ?? this.$t('search_result_role_advisor')
        )
        return {
          contentText: this.$t('search_result_person_with_roles', {
            personName: advisorName,
            roles: advisorType,
          }),
          contentRouter,
        }
      })
    },
    setNavigateBackUrl() {
      this.$utils.setSessionStorage(
        'LINE_DETAIL_BACK_LINK',
        this.$route.fullPath
      )
    },
    getCollateralResultContents(result) {
      const assets = result?.assetNumbers ?? []
      const showMoreAssets =
        assets.length > ResultToggleMoreLength.lineAssets ? false : null

      const contentRouter = this.getLineContentPath(result?.loanFacilityId)
      const parties = this.normalizeLineParties(result, contentRouter)
      const advisors = this.normalizeLineAdvisors(result, contentRouter)
      const resultContents = this.$lodash.concat(parties, advisors)

      const showMoreContent =
        resultContents.length > ResultToggleMoreLength.linePerson ? false : null

      const resultRouter = `/service/aperture/collateral?collateralId=${
        result?.collateralId
      }&&loanFacilityId=${
        result?.loanFacilityIds && result.loanFacilityIds.length !== 0
          ? result?.loanFacilityIds[0]
          : ''
      }&&totalLoanFacilityId=${result?.totalLoanFacilityId}`

      return {
        assets,
        resultContents,
        resultRouter,
        showMoreAssets,
        showMoreContent,
      }
    },
    getLineResultContents(result) {
      const assets = result?.assetNumbers ?? []
      const showMoreAssets =
        assets.length > ResultToggleMoreLength.lineAssets ? false : null

      const contentRouter = this.getLineContentPath(result?.loanFacilityId)
      const parties = this.normalizeLineParties(result, contentRouter)
      const advisors = this.normalizeLineAdvisors(result, contentRouter)
      const resultContents = this.$lodash.concat(parties, advisors)

      const showMoreContent =
        resultContents.length > ResultToggleMoreLength.linePerson ? false : null

      const lineDetailPath = this.isInvestment
        ? '/service/activity-center/my-line'
        : '/service/line-detail'
      const resultRouter = `${lineDetailPath}?loanFacilityId=${result?.loanFacilityId}&totalLoanFacilityId=${result?.totalLoanFacilityId}`

      return {
        assets,
        resultContents,
        resultRouter,
        showMoreAssets,
        showMoreContent,
      }
    },
    getAssetResultContents(result) {
      const resultRouter = this.$router.resolve({
        path: collateralPath,
        query: {
          collateralId: result?.collateralId,
          loanFacilityId: result?.loanFacilityIds?.[0],
          totalLoanFacilityId: result?.totalLoanFacilityId,
        },
      })
      return {
        assets: [result.assetNumber],
        resultRouter: resultRouter?.href,
        resultContents: {},
      }
    },
    getLoanResultContents(result) {
      const loanDetailPath = '/service/line-detail'
      const resultRouter = `${loanDetailPath}?totalLoanFacilityId=${result?.totalLoanFacilityId}`

      return {
        assets: [result.loanNumber],
        resultRouter,
        resultContents: {},
      }
    },
    cmNormalizeResult(result, getResultContents, typeCode) {
      const locNumber = this.$utils.format.text(result?.loanFacilityNumber)
      const lineName = this.$utils.format.text(result?.loanFacilityName)
      let resultTitle = `${lineName} (${locNumber})`
      if (typeCode === GroupTypeEnum.collateral) {
        resultTitle = `${this.$utils.format.text(
          result?.collateralName
        )} (${result.loanFacilityNumbers?.join(', ')})`
      }
      if (typeCode === GroupTypeEnum.asset) {
        resultTitle = `${this.$utils.format.text(
          result?.assetName
        )} (${result.loanFacilityNumbers.join(', ')})`
      }
      return {
        resultTitle,
        setNavigateBackUrl: this.setNavigateBackUrl,
        ...getResultContents(result),
      }
    },
  },
}
