export default {
  allowedTags: [
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'blockquote',
    'p',
    'a',
    'ul',
    'ol',
    'nl',
    'li',
    'b',
    'i',
    'strong',
    'em',
    'strike',
    'hr',
    'br',
    'div',
    'img',
    'span',
    'figure',
    'table',
    'tbody',
    'thead',
    'tr',
    'th',
    'td',
    'sup',
    'sub',
    's',
    'label',
    'input',
  ],
  disallowedTagsMode: 'discard',
  allowedAttributes: {
    a: ['href', 'name', 'target', 'class', 'style'],
    // We don't currently allow img itself by default, but this
    // would make sense if we did. You could add srcset here,
    // and if you do the URL is checked for safety
    img: ['src', 'height', 'width', 'alt', 'style'],
    div: ['class', 'style'],
    h1: ['class', 'style'],
    h2: ['class', 'style'],
    h3: ['class', 'style'],
    h4: ['class', 'style'],
    h5: ['class', 'style'],
    h6: ['class', 'style'],
    p: ['class', 'style', 'data-mailid'],
    span: ['class', 'style'],
    strong: ['class', 'style'],
    em: ['class', 'style'],
    table: ['class', 'style', 'width', 'role', 'cellspacing', 'cellpadding'],
    tr: ['class', 'style'],
    td: ['class', 'style', 'colspan', 'align', 'rowspan'],
    ul: ['class', 'style'],
    li: ['class', 'style'],
    header: ['class', 'style'],
    footer: ['class', 'style'],
    section: ['class', 'style'],
    article: ['class', 'style'],
    figure: ['class', 'style'],
    figcaption: ['class', 'style'],
    ol: ['class', 'style'],
    nl: ['class', 'style'],
    dl: ['class', 'style'],
    dt: ['class', 'style'],
    dd: ['class', 'style'],
    sup: ['class', 'style'],
    sub: ['class', 'style'],
    code: ['class', 'style'],
    i: ['class', 'style'],
    hr: ['class', 'style'],
    b: ['class', 'style'],
    strike: ['class', 'style'],
    s: ['class', 'style'],
    label: ['class', 'style'],
    input: ['class', 'style', 'checked', 'type', 'disabled'],
  },
  // Lots of these won't come up by default because we don't allow them
  selfClosing: [
    'img',
    'br',
    'hr',
    'area',
    'base',
    'basefont',
    'input',
    'link',
    'meta',
  ],
  // URL schemes we permit
  allowedSchemes: ['https', 'mailto', 'http', 'tel'],
  allowedSchemesByTag: {},
  allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
  allowProtocolRelative: true,
}
