import {
  GroupTypeEnum,
  ModulePathsEnum,
  ResultToggleMoreLength,
  RiskModeEnum,
  RoleTypeEnum,
  AuthEnum,
} from './global-search.enum'
import { configurationAPI } from '@/shared/api/index'
import { APIStageEnum } from '@/shared/utils'

const lineListPath = '/service/line-list'
const drawRequestPath = '/service/action-center/draw-request-list'
const riskEventPath = '/service/line/collateral-call'
const lineCollateralPath = '/service/line/collateral-accounts'
const collateralReleasePath = '/service/action-center/collateral-release'

export default {
  data() {
    return {
      isCallMode: true,
    }
  },
  computed: {
    roleMenuList() {
      return this.$store.state?.userInfo?.roleConfiguration?.menuList ?? []
    },
    isInvestment() {
      const roleType = this.$store.state.userInfo?.profile?.roleType
      return this.$utils.isEqualStringIgnoreCase(
        RoleTypeEnum.investment,
        roleType
      )
    },
    serviceSearchGroups() {
      return [
        {
          groupName: this.$t('search_result_group_line'),
          typeCode: GroupTypeEnum.line,
          requiredMenu: lineListPath,
          normalizeResult: this.serviceNormalizeResult,
          getResultContents: this.getLineResultContents,
          modulePaths: ModulePathsEnum.service,
        },
        {
          groupName: this.$t('search_result_group_draw_request'),
          typeCode: GroupTypeEnum.drawRequest,
          requiredMenu: drawRequestPath,
          normalizeResult: this.serviceNormalizeResult,
          getResultContents: this.getDrawRequestResultContents,
          modulePaths: ModulePathsEnum.service,
        },
        {
          groupName: this.isCallMode
            ? this.$t('search_result_group_collateral_call')
            : this.$t('search_result_group_collateral_shortfall'),
          typeCode: GroupTypeEnum.collateralCall,
          requiredMenu: lineCollateralPath,
          normalizeResult: this.serviceNormalizeResult,
          getResultContents: this.getRiskEventResultContents,
          modulePaths: ModulePathsEnum.service,
        },
        {
          groupName: this.$t('search_result_group_collateral_release_request'),
          typeCode: GroupTypeEnum.collateralRelease,
          requiredMenu: collateralReleasePath,
          normalizeResult: this.serviceNormalizeResult,
          getResultContents: this.getCollateralReleaseResultContents,
          modulePaths: ModulePathsEnum.service,
        },
      ].filter((searchType) => {
        return this.roleMenuList.some(
          (menu) => menu.path === searchType.requiredMenu
        )
      })
    },
    needSearchRiskEvent() {
      return this.searchTypes.includes(GroupTypeEnum.collateralCall)
    },
  },
  created() {
    if (this.needSearchRiskEvent) {
      this.getCMConfig()
    }
  },
  methods: {
    getCMConfig() {
      configurationAPI
        .listInstitutionLevelConfigs(APIStageEnum.service, {
          categoryCode: 'collateralDataHub',
        })
        .then((cmInstitutionConfig) => {
          const riskEventMode =
            cmInstitutionConfig.lenderInstitutionLevelConfigs.riskEventMode
          this.isCallMode = this.$utils.isEqualStringIgnoreCase(
            riskEventMode,
            RiskModeEnum.call
          )
        })
    },
    getLineContentPath(locId) {
      const peopleEntitiesPath = '/service/line/people-and-entities'
      const contentNeedAuth = this.roleMenuList.some(
        (menu) => menu.path === peopleEntitiesPath
      )
      return contentNeedAuth ? `${peopleEntitiesPath}?locId=${locId}` : null
    },
    normalizeLineParties(result, contentRouter) {
      const parties = result?.parties ?? []
      return parties.map((item) => {
        let roles = item?.roles ?? []
        roles = this.$utils.format.text(roles.join(', '))
        const partyName = this.$utils.format.text(item?.partyName)
        return {
          contentText: `${partyName} (${roles})`,
          contentRouter,
        }
      })
    },
    normalizeLineAdvisors(result, contentRouter) {
      const advisors = result?.advisors ?? []
      return advisors.map((item) => {
        const advisorName = this.$utils.format.text(item?.advisorName)
        const advisorType = this.$utils.format.text(
          item?.advisorType ?? this.$t('search_result_role_advisor')
        )
        return {
          contentText: this.$t('search_result_person_with_roles', {
            personName: advisorName,
            roles: advisorType,
          }),
          contentRouter,
        }
      })
    },
    getLineResultContents(result) {
      const assets = result?.assetNumbers ?? []
      const showMoreAssets =
        assets.length > ResultToggleMoreLength.lineAssets ? false : null

      const contentRouter = this.getLineContentPath(result?.loanFacilityId)
      const parties = this.normalizeLineParties(result, contentRouter)
      const advisors = this.normalizeLineAdvisors(result, contentRouter)
      const resultContents = this.$lodash.concat(parties, advisors)

      const showMoreContent =
        resultContents.length > ResultToggleMoreLength.linePerson ? false : null

      const lineDetailPath = this.isInvestment
        ? '/service/activity-center/my-line'
        : '/service/line-detail'
      const resultRouter = `${lineDetailPath}?loanFacilityId=${result?.loanFacilityId}&totalLoanFacilityId=${result?.totalLoanFacilityId}`

      return {
        assets,
        resultContents,
        resultRouter,
        showMoreAssets,
        showMoreContent,
      }
    },
    getDrawRequestResultContents(result) {
      const drawRequestsList = result?.drawRequestInfos ?? []

      const resultContents = drawRequestsList.map((drawRequest) => {
        const drawRequestedBy = this.$utils.format.text(
          drawRequest?.drawRequestedBy
        )
        const drawRequestAmount = this.$utils.format.currency(
          drawRequest?.drawRequestAmount
        )
        return {
          contentText: this.$t('search_result_draw_request', {
            drawRequestBy: drawRequestedBy,
            drawRequestAmount: drawRequestAmount,
          }),
        }
      })
      const resultRouter = `${drawRequestPath}?searchKey=${result?.loanFacilityNumber}`
      return {
        resultRouter,
        resultContents,
      }
    },
    getCollateralReleaseResultContents(result) {
      const collateralReleaseList = result?.collateralReleaseRequestInfos ?? []
      const resultContents = collateralReleaseList.map((collateralRelease) => {
        const releaseAmount = this.$utils.format.currency(
          collateralRelease?.releaseAmount
        )
        const releaseDetailPath =
          '/service/action-center/collateral-release/collateral-release-detail'
        return {
          contentText: this.$t('search_result_collateral_release_request', {
            releaseAmount: releaseAmount,
          }),
          contentRouter: `${releaseDetailPath}?locId=${result?.loanFacilityId}&collateralReleaseId=${collateralRelease?.collateralId}`,
        }
      })

      const resultRouter = `${collateralReleasePath}?searchKey=${result?.loanFacilityNumbers.join(
        ','
      )}`
      return {
        resultRouter,
        resultContents,
      }
    },
    getRiskEventResultContents(result) {
      const riskEventList = result?.collateralCallInfos ?? []
      const locId = result?.totalLoanFacilityId ?? ''

      const collateralRiskEventPath = this.isInvestment
        ? lineCollateralPath
        : riskEventPath

      const resultContents = riskEventList.map((riskEvent) => {
        const eventNumber = this.$utils.format.text(riskEvent?.eventNumber)
        const eventValue = this.$utils.format.currency(riskEvent?.eventValue)
        const riskEventId = riskEvent?.riskEventId ?? ''

        const serviceRiskDetailAuth = this.hasAuthed(
          'viewCallDetail',
          AuthEnum.open,
          'CLIENT_SERVICE_COLLATERAL_CALL'
        )
        const advisorDetailAuth = this.hasAuthed(
          'viewCallDetail',
          AuthEnum.open,
          'ADVISOR_COLLATERAL'
        )

        const detailNeedAuth = serviceRiskDetailAuth || advisorDetailAuth
        const contentRouter = detailNeedAuth
          ? `${collateralRiskEventPath}?locId=${locId}&riskEventId=${riskEventId}`
          : null
        return {
          contentText: `${eventNumber} | ${eventValue}`,
          contentRouter,
        }
      })

      const resultRouter = `${collateralRiskEventPath}?locId=${locId}`
      return {
        resultContents,
        resultRouter,
      }
    },
    serviceNormalizeResult(result, getResultContents, typeCode) {
      const locNumber = this.$utils.format.text(result?.loanFacilityNumber)
      const lineName = this.$utils.format.text(result?.loanFacilityName)
      let resultTitle = `${lineName} (${locNumber})`
      if (
        [
          GroupTypeEnum.collateralRelease,
          GroupTypeEnum.collateralCall,
        ].includes(typeCode)
      ) {
        resultTitle = `${this.$utils.format.text(
          result?.collateralName
        )} (${result.loanFacilityNumbers.join(', ')})`
      }
      return {
        resultTitle,
        ...getResultContents(result),
      }
    },
  },
}
